<template>
  <div
    @scroll="isBottom"
    id="list-purchase"
  >
    <a-modal
      v-model="deleteModal"
      class="modal-delete"
      :footer="null"
      width="300px"
    >
      <div class="content flex-column d-flex align-items-center">
        <div class="align-self-center"> <TrashIcon :width="'80'" :height="'80'" /> </div>

        <div
          class="mt-2"
          style="
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            color: #015289;
          "
        >
          Apakah anda yakin ingin menghapus alamat?
        </div>

        <div class="mt-3 w-100 d-flex flex-column justify-content-center align-items-center" style="gap: 10px 0px">
          <div
            class="mx-2 w-100 d-flex align-items-center justify-content-center"
            style="
              height: 33px;
              left: 119px;
              top: 159px;
              background: #FFFFFF;
              border: 1px solid #015289;
              box-sizing: border-box;
              border-radius: 5px;
              cursor: pointer;
            "
            @click.prevent="() => onDeleteShippingAddress()"
          >
            <span style="color: #015289" v-if="!loading">
            Hapus
            </span>
            <span style="color: #015289" v-if="loading"> <a-icon type="loading" /> </span>
          </div>
          <div
            class="mx-2 w-100 d-flex align-items-center justify-content-center"
            style="
              height: 33px;
              left: 216px;
              top: 159px;
              background: #015289;
              border-radius: 5px;
              cursor: pointer;
            "
            @click.prevent="() => deleteModal = false"
          >
            <span style="color: #FFFFFF">
            Batalkan
            </span>
          </div>
        </div>
      </div>
    </a-modal>

    <h2 class="mb-5">Daftar Alamat</h2>
    <div class="d-flex col pr-0 justify-content-between mb-3">
      <div class="d-flex container-button-type gap-12 justify-content-between align-items-center mb-3 sub-text">
        Alamat Pengiriman
      </div>
      <div
        @click="$router.push({ path: '/account/shipping-address/add'})"
        class="btn d-flex rounded btn-primary align-self-end align-items-center justify-content-between mb-3"
      >
        <span class="mx-2">+ Tambah Alamat</span>
      </div>
    </div>
    <div
      class="d-flex pr-0 justify-content-center align-items-center flex-column pt-5"
      v-if="!loading && listShippingAddress.length === 0"
    >
      <div
        class="mt-3 ml-2 h4 align-items-center d-flex flex-column"
      >
        <div class="d-flex align-items-center mb-4">
          <EmptyIcon />
        </div>
        <span
          style="color: #015CA1"
          class="ml-2 desc-empty-state text-dark"
        >
          Anda belum menambahkan alamat
        </span>
      </div>
    </div>
    <div
      v-else
      id="wrap"
      class="col pr-0"
      v-for="(item, index) in listShippingAddress"
      :key="index"
    >
      <div class="card col-12 d-flex justify-content-center">
        <div class="card-body d-flex flex-column">
          <div class="text-name">{{ `${item.first_name} ${item.last_name}` }}</div>
          <div class="text-address pt-2">{{ item.phone }}</div>
          <div class="text-address">{{ `${item.address_line1 ? item.address_line1 + ', ' : ''}${item.district ? item.district + ', ' : ''}${item.province ? item.province + ', ' : ''}${item.country ? item.country : ''} ${item.postal_code ? item.postal_code : ''}` }}</div>
        </div>
        <div class="card-footer border-0 d-flex align-items-center flex-row-reverse" style="gap: 0px 10px;">
          <button
            class="btn-edit"
            size="large"
            @click="$router.push({ path: `/account/shipping-address/edit/${item.id}`})"
          >
            <a-icon type="edit" style="color: #015CA1" />
            {{ $t('account.edit') }}
          </button>
          <button
            class="btn-delete"
            size="large"
            @click="selectedId = item.id, deleteModal = true"
          >
            <a-icon type="delete" style="color: #FF0000" />
            Hapus
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrashIcon from '@/components/Icons/Trash2.vue'

export default {
  components: {
    TrashIcon,
  },
  name: 'ShippingAddress',
  data() {
    return {
      loading: false,
      isPayModal: false,
      modalVisible: false,
      modalTitle: '',
      getSummaryCompletion: {},
      getBillingContent: {},
      statusPaid: {
        UNPAID: true,
        PAID: false,
      },
      listBillPayment: [],
      data: [],
      listShippingAddress: [],
      deleteModal: false,
      selectedId: null,
    }
  },
  methods: {
    fetchShippingAddress({ addr_key }) {
      this.$store.dispatch('purchase/GETSHIPPINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        addr_key,
      })
        .then(({ data }) => {
          this.listShippingAddress = data.filter((obj) => !obj.deleted_at) ?? []
        })
        .catch(() => {
          this.$refs.shippingComponent.$data.isCreateNewAddress = true
        })
    },
    onDeleteShippingAddress() {
      this.$store.dispatch('purchase/DELSHIPPINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        id: this.selectedId,
      })
        .then(({ data }) => {
          this.selectedId = null
          this.deleteModal = false
          this.fetchShippingAddress({ addr_key: 'all' })
        })
        .catch(() => {
          this.$refs.shippingComponent.$data.isCreateNewAddress = true
        })
    },
    isBottom() {
      // const el = document.querySelectorAll('#wrap')
      // const navbarHeight = 151
      // const offsetElement = 5
      // const lastEl = el[el.length - offsetElement]
      // const bounds = lastEl.getBoundingClientRect().y
      // const alreadyGetAllData = this.data.length >= this.total
      // if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
      //   const getValueByKey = this.listTab.find(({ key }) => key === this.activeKey)
      //   this.fetchPurchase(getValueByKey?.value, true)
      // }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.isBottom)
  },
  mounted: function () {
    this.fetchShippingAddress({ addr_key: 'all' })
  },
}
</script>

<style lang="scss">
.sub-text {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.btn-edit {
  width: 110px;
  background: #E8F6FE;
  border: 1px solid #015289;
  color: #015289;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;
  &:hover {
    background: #cfeafa;
    color: #015289;
  }
}
.btn-delete {
  width: 110px;
  background: #FEE8E8;
  border: 1px solid #FF0000;
  color: #FF0000;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;
  &:hover {
    background: #f7d4d4;
    color: #FF0000;
  }
}
.text-name {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.text-address {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.ant-modal .modal-delete {
  .ant-modal-content {
    border-radius: 5px !important;
    height: 250px !important;

    .ant-modal-header {
      background: #015289 !important;

      .ant-modal-title {
        color: #000000 !important;
      }
    }

    .ant-modal-body {
      .content {
        display: flex !important;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        margin-top: -3rem;

        &-trash-icon {
          background: #FFFFFF !important;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
          padding: 1rem 1.5rem;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
