<template>
  <svg :width="width" :height="height" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" fill="#F3F3F4"/>
  <path d="M21.0742 29.6484L23.6688 60.9702C23.8548 63.229 25.7788 65 28.0462 65H51.9529C54.2203 65 56.1443 63.229 56.3303 60.9702L58.9248 29.6484H21.0742ZM32.6739 59.1406C31.9071 59.1406 31.262 58.5441 31.2134 57.7673L29.7485 34.1345C29.6984 33.3263 30.3121 32.6311 31.1189 32.581C31.9558 32.5223 32.621 33.1432 32.6725 33.9514L34.1373 57.5842C34.1892 58.4211 33.527 59.1406 32.6739 59.1406ZM41.4644 57.6758C41.4644 58.4854 40.8092 59.1406 39.9995 59.1406C39.1898 59.1406 38.5347 58.4854 38.5347 57.6758V34.043C38.5347 33.2333 39.1898 32.5781 39.9995 32.5781C40.8092 32.5781 41.4644 33.2333 41.4644 34.043V57.6758ZM50.2505 34.1346L48.7856 57.7674C48.7375 58.5363 48.0966 59.1771 47.2321 59.1378C46.4253 59.0877 45.8116 58.3925 45.8617 57.5843L47.3266 33.9515C47.3767 33.1433 48.0848 32.5553 48.8801 32.5811C49.6869 32.6312 50.3006 33.3264 50.2505 34.1346Z" fill="#CACACA"/>
  <path d="M59.043 20.8594H50.2539V19.3945C50.2539 16.9713 48.2826 15 45.8594 15H34.1406C31.7174 15 29.7461 16.9713 29.7461 19.3945V20.8594H20.957C19.339 20.8594 18.0273 22.171 18.0273 23.7891C18.0273 25.4069 19.339 26.7188 20.957 26.7188C34.4303 26.7188 45.5702 26.7188 59.043 26.7188C60.661 26.7188 61.9727 25.4069 61.9727 23.7891C61.9727 22.171 60.661 20.8594 59.043 20.8594ZM47.3242 20.8594H32.6758V19.3945C32.6758 18.5863 33.3324 17.9297 34.1406 17.9297H45.8594C46.6676 17.9297 47.3242 18.5863 47.3242 19.3945V20.8594Z" fill="#CACACA"/>
  </svg>
</template>

<script>
export default {
  name: 'trash2',
  props: ['width', 'height'],
}
</script>
